import React from "react";

export default function Footer() {
  return (
    <div>
      
      <footer
        id="rs-footer"
        className="rs-footer footer-main-home footer-modify10"
      >
        <div className="container">
          <div className="footer-top">
            <div className="row text-start align-items-center">
              <div className="col-lg-4 md-mb-20 text-start">
                <div className="footer-logo" style={{cursor:"pointer"}}>
                  <a href="https://likemecoin.com/">
                    <img src="logo_dark.png" alt="" />
                  </a>
                </div>
                <p className="widget-desc">
                LikeME is a decentralized social media platform that aims to transform the way people interact online. 

                </p>
                <ul className="footer-social md-mb-30">
                  <li>
                    {/* href="https://www.facebook.com/people/LikeMe-Coin/61557800742474" */}
                    <a  target="_blank">
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li>
                    {/* href="https://www.instagram.com/likemecoin" */}
                    <a target="_blank" >
                      <i className="fa fa-instagram" />
                    </a>
                  </li>
                  <li>
                    {/*  href="https://x.com/LikeMeCoin" */}
                    <a target="_blank">
                      <i className="fa fa-twitter" />
                    </a>
                  </li>
                  <li>
                    {/* href="https://t.me/likeme_en" */}
                    <a target="_blank" >
                      <i className="fa fa-telegram" />
                    </a>
                  </li>
                  <li>
                    {/* href="https://www.youtube.com/channel/UCZJNs8cQQR46h555wMEG4FQ" */}
                    <a target="_blank"  >
                    <i class="fa fa-youtube"></i>                    </a>
                  </li>
                  <li>
                    {/* href="https://www.linkedin.com/in/likemecoin/recent-activity/all/" */}
                    <a target="_blank"  >
                    <i class="fa fa-brands fa-linkedin"></i>            </a>
                  </li>


                </ul>
              </div>
              <div className="col-lg-8 pl-110 md-pl-15">
                <div className="row">
                  <div className="col-lg-4 md-mb-10">
                    <h3 className="footer-title">Learn</h3>
                    <ul className="site-map">
                      {/* <li><a href="#">Learn</a></li> */}

                      <li>
                        {/*  href="https://likeme.gitbook.io/likeme-whitepaper" */}
                        <a target="_blank">Whitepaper </a>
                      </li>
                      <li>
                        {/*  href="https://likeme.gitbook.io/one-pager" */}
                        <a   target="_blank">One-pager </a>
                      </li>
                      <li>
                        {/* href="https://likeme.gitbook.io/likeme-whitepaper/media-kit" */}
                        <a target="_blank" >Media Kit </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-4 md-mb-10">
                    <h3 className="footer-title">Important Links</h3>
                    <ul className="site-map">
                      <li>
                        <a>$LMC Token</a>
                      </li>
                      <li>
                        <a >Roadmap</a>
                      </li>
                      <li>
                        {/* href="https://presale.likemecoin.com" */}
                        <a >Presale </a>
                      </li>
                      <li>
                        {/* href="https://airdrop.likemecoin.com" target="_blank" */}
                        <a >Airdrop </a>
                      </li>
                    </ul>
                  </div>
                  
                  <div className="col-lg-4">
                    <h3 className="footer-title">Download App!
                    </h3>
                    <ul className="textwidget-btn">
                    {/* https://allstarmusicempire.com/wp-content/uploads/2015/08/comingsoon.png */}
                      <li>
                          <img src="assets/images/newbg/playstore-1.png" style={{cursor:"pointer"}} alt=""  />
                      </li>
                      <li>
                          <img src="assets/images/newbg/appstore.png" style={{cursor:"pointer"}} className="mt-2"  alt="" />
                      </li>


                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="bottom-border">
              <div className="row y-middle">
                <div className="col-lg-6 md-mb-10 text-lg-end text-center order-last">
                  <ul className="copy-right-menu">
                    <li>
                      <a  style={{fontWeight:"400"}}>Privacy Policy</a>
                    </li>
                    <li>
                      <a  style={{fontWeight:"400"}}>Terms & Conditions</a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6">
                  <div className="copyright text-lg-start text-center" >
                    <p style={{fontWeight:"500"}}>
                      © 2024 LikeME | All Rights Reserved.
                     
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <div id="scrollUp" class="blue-color3" style={{display:"block"}}>
			<i class="fa fa-angle-up"></i>
		</div> */}
    
    </div>
  );
}
