/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function Header() {
  const [showColor, setShowColor] = useState(0);
  const epoint = useLocation().pathname;

  const [headerShow, setHeaderShow] = useState(false);
  const toggleHeaderShow = () => {
    if (headerShow === "defult-home") {
      setHeaderShow("defult-home nav-expanded");
    } else {
      setHeaderShow("defult-home");
    }
  };
  useEffect(() => {
    document.body.className = headerShow;
  }, [headerShow]);
  return (
    <div>
      <div className="full-width-header">
        {/*Header Start*/}
        <header
          id="rs-header"
          className="rs-header header-style1 header-modify7"
        >
          {/* Menu Start */}
          <div className="heder-bg p-0">
            <div className="menu-area menu-sticky">
              <p className="text-center blink_me mb-0 py-2">
                <a href="" style={{ color: "#e31e24", fontWeight: "600" }}>
                  LikeME Airdrop - $50,000 Prize Pool | Claim Free LMC Tokens [
                  Join Now ]
                </a>
              </p>

              <div
                className="container-fluid2 bg-white"
                style={{ borderRadius: "25px 25px 0px 0px" }}
              >
                <div className="row-table">
                  <div className="col-cell header-logo py-3">
                    <div className="logo-area" >
                      <a href="https://likemecoin.com/">
                        <img
                          className="normal-logo img-fluid"
                          src="logo_dark.png"
                          alt="logo"
                        />
                        <img
                          className="sticky-logo"
                          src="logo_dark.png"
                          alt="logo"
                        />
                      </a>
                    </div>
                  </div>
              
                
                </div>
              </div>
            </div>
          </div>
          {/* Menu End */}
          {/* Canvas Mobile Menu start */}
          <nav
            className="right_menu_togle mobile-navbar-menu"
            id="mobile-navbar-menu"
          >
            <div className="close-btn">
              <a
                id="nav-close2"
                className="nav-close"
                onClick={() =>
                  toggleHeaderShow(headerShow === "defult-home nav-expanded")
                }
              >
                <div className="line">
                  <span className="line1" />
                  <span className="line2" />
                </div>
              </a>
            </div>
            <ul className="nav-menu">
              <li onClick={() => setShowColor(4)}>
                <a href="/download" className={epoint === "/download" ? "color--2" : ""}>
                  Download
                </a>
              </li>
              <li onClick={() => setShowColor(5)}>
                <a
                  href="/$lmc-token"
                  className={epoint === "/$lmc-token" ? "color--2" : ""}
                >
                  $LMC Token
                </a>
              </li>
              <li onClick={() => setShowColor(6)}>
                <a
                  href="/learn"
                  className={epoint === "/learn" ? "color--2" : ""}
                >
                  Learn
                </a>
              </li>
              <li onClick={() => setShowColor(7)}>
                <a
                  href="/coming-soon"
                  className={epoint === "/coming-soon" ? "color--2" : ""}
                >
                  NFTs
                </a>
              </li>
            </ul>{" "}
            {/* //.nav-menu */}
            {/* //.nav-menu */}
            {/* //.nav-menu */}
            <div className="canvas-contact">
              <ul className="d-flex flex-wrap">
                <li className="btn-quote m-2">
                  <a href="https://airdrop.likemecoin.com" target="_blank">
                    <span className="btn-text">Airdrop </span>
                  </a>
                </li>
                <li className="btn-quote m-2">
                  <a href="https://presale.likemecoin.com">
                    <span className="btn-text"> Presale</span>
                  </a>
                </li>
                <li className="btn-quote m-2">
                  <a href="https://affiliate.likemecoin.com">
                    <span className="btn-text"> Launch App</span>
                  </a>
                </li>
              </ul>
            </div>
          </nav>
          {/* Canvas Menu end */}
        </header>
        {/*Header End*/}
      </div>
    </div>
  );
}
